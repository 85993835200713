body,
.ui.menu,
.ui.button,
.ui.accordion .title:not(.ui) {
    font-family: Montserrat !important
}

.ui.accordion .title:not(.ui) {
    display: flex;
    font-weight: 500 !important;
    justify-content: space-between;
}

.header,
.ui.header,
.ui.card > .content > .header,
.ui.cards > .card > .content > .header {
    font-family: Montserrat !important;
    font-weight: 500 !important
}

.ui.button.tip_button {
    font-weight: 500;
}

.ui.inverted.button {
    box-shadow: none !important;
}

.ui.form .field > label {
    font-weight: 600 !important
}

div.sixteen.wide column.order_total {
    width: 101% !important;
}

.bg-text .ui.form .field > label {
    color: black !important;
    text-align: left;
}

.bg-text .ui.segment {
    padding: 35px;
}

.ui.teal.button,
.ui.teal.buttons .button {
    background-color: #0db77d !important;
}

.ui.dimmer {
    text-align: left !important;
}

.bg-image {
    width: 100%;
    min-height: 100vh;
}

.visibility-container {
    z-index: 3;
}

.bg-image-half {
    background-color: #0db77d;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    width: 100%;
    height: 50vh;
}

/* Position text in the middle of the page/image */
.bg-text {
    color: black;
    font-weight: bold;
    position: relative;
    top: 0px;
    /*left: 50%;*/
    /*transform: translate(-50%, -50%);*/
    z-index: 2;
    width: 100%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    background-repeat: no-repeat;
}

body:after {
    content: "";
    position: fixed; /* stretch a fixed position to the whole screen */
    top: 0;
    height: 100vh; /* fix for mobile browser address bar appearing disappearing */
    left: 0;
    right: 0;
    z-index: -1; /* needed to keep in the background */
    background-attachment: fixed !important;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position-x: center !important;
    background-position-y: top !important;
}

.footer .ui.grid {
    margin: 0
}

.ui.fluid.container.ui-content {
    padding: 0px
}

.ui-banner {
    border-bottom: none !important;
}

#root {
    min-height: 100vh;
}

:root {
    --top-menu-height: 4em;
}

.top-menu {
    height: var(--top-menu-height);
}

.layout-grid {
    width: 100%;
    display: grid;
    grid: var(--top-menu-height) / 1fr;
    grid-template-areas: 'menu' 'maincontent';
}

.row {
    margin-left: 0px;
}

.menu {
    grid-area: menu;
    z-index: 100;
}

.main-content {
    grid-area: maincontent;
    background-color: #f9fafb;
}

.parent {
    display: inline-flex;
    width: 100%;
}

.side {
    transition: width 0.3s;
    width: 14em !important;
    overflow: hidden;
    padding-top: var(--top-menu-height);
    z-index: 99;
}

.small-side {
    width: 4em !important;
    flex-basis: 4em;
}

.normal-content {
    right: 0;
    transition: all 0.3s;
    padding: 10px;
    width: calc(100% - 14em);
    background-color: #f5f5f5;
}

.small-content {
    padding: 10px;
    width: calc(100% - 4em);
}

.logo-space-menu-item {
    width: 14em;
}

.display-inline {
    display: inline-flex;
    align-items: center;
}

.logo-space {
    font-family: 'Roboto Light', serif;
    font-size: 24px;
    margin: 0 auto;
}

.logo-space img,
.display-inline img {
    margin-right: 8px;
}

.no-border {
    border: none !important;
    box-shadow: none !important;
}

.no-border::before {
    display: none;
}

.top-menu i.icon {
    margin: 0 !important;
}

.drop-left-padding {
    padding-left: 0 !important;
}

.label-on-corner {
    top: 0.8em !important;
    left: 78% !important;
}

.ui.form.editing input[type='text'],
.ui.form.editing input[type='number'],
.ui.form.editing .ui.selection.dropdown {
    border: 1px solid rgba(0, 181, 173, 0.72) !important;
}

.ui.form.editing .field > label {
    font-weight: 700;
}

.ui.form.readOnly .field > label {
    font-weight: 500;
}

.ui.form.editing > .ui.toggle.checkbox input:checked ~ .box:before,
.ui.form.editing > .ui.toggle.checkbox input:checked ~ label:before {
    background-color: #00b5ad !important;
}

.ui.form.editing > .ui.toggle.checkbox input ~ .box:before,
.ui.form.editing > .ui.toggle.checkbox input ~ label:before {
    background-color: #db2828 !important;
}

.ui.form.editing > .ui.grid > div.column > .ui.toggle.checkbox input:checked ~ .box:before,
.ui.form.editing > .ui.grid > div.column > .ui.toggle.checkbox input:checked ~ label:before {
    background-color: #00b5ad !important;
}

.ui.form.editing > .ui.grid > div.column > .ui.toggle.checkbox input ~ .box:before,
.ui.form.editing > .ui.grid > div.column > .ui.toggle.checkbox input ~ label:before {
    background-color: #db2828 !important;
}

.ui.form.editing > .ui.grid > div.column > .ui.toggle.checkbox input {
    left: 22px;
}

.ui.form.editing > .ui.grid > div.column > .ui.toggle.checkbox {
    margin-top: 3px;
}

.ui.selection.dropdown .menu {
    max-height: 10rem !important;
}

.panel-title {
    font-size: 14px !important;
}

.ui.greensea.header {
    color: #006a7a;
}

.ui.card > .button:last-child,
.ui.cards > .card > .button:last-child {
    border-radius: 0.28571429rem !important;
    width: 31px;
    position: absolute;
    right: 5px;
    bottom: 5px;
    background: rgba(255, 255, 255, 0.58);
    color: red;
    border: 1px solid rgba(0, 181, 173, 0.72);
}

.ui.toggle.checkbox {
    margin-top: 10px !important;
}

.ui.styled.accordion .accordion .content {
    padding: 0em 1em 1em !important;
}

.ui.styled.accordion .accordion .content,
.ui.styled.accordion .content {
    padding: 0.5em 1em 1em !important;
}

.timeline div h4 {
    width: 150px;
    text-align: right;
    position: absolute;
    left: -200px;
    top: 5px;
    color: black;
}

.timeline .loader {
    margin-left: 180px;
}

input[type='date']::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}

.ui.table td.accordion-report.active td,
.ui.table tr.accordion-report.active td {
    background: #f3937ecc !important;
}

.ui.table.accordion-report {
    background: #f3937e77 !important;
}

.ui.table.legend {
    border: 1px solid rgba(34, 36, 38, 1);
}

tr.active.title.accordion-report ~ tr.title.accordion-report > td {
    border-top: 1px solid black;
}

tr.active.title.accordion-report td {
    border-top: 1px solid black;
}

div.jsoneditor {
    height: 500px !important;
}

.ui.positive.message {
    background-color: #d4e0b9 !important;
}

.ui.vertical.menu .item .menu .active.item {
    background: rgba(0, 0, 0, 0.05) !important;
    border-radius: 0;
    box-shadow: none;
}

.ui table tr td {
    vertical-align: middle;
}

.Checkout {
    margin: 0 auto;
    max-width: 800px;
    box-sizing: border-box;
    padding: 0 5px;
}

input {
    font-weight: 400;
}

input,
.StripeElement {
    display: block;
    margin: 10px 0 20px 0;
    padding: 10px 14px;
    font-size: 1em;
    font-family: 'Source Code Pro', monospace;
    box-shadow: rgba(50, 50, 93, 0.14902) 1px 1px 5px,
    rgba(0, 0, 0, 0.0196078) 1px 1px 0px;
    border: 1px solid rgba(34, 36, 38, 0.15);
    outline: 0;
    border-radius: 4px;
    background: white;
}

input::placeholder {
    color: #aab7c4;
}

input:focus,
.StripeElement--focus {
    box-shadow: rgba(50, 50, 93, 0.109804) 5px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 5px 1px 3px;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
}

.ui.progress.mygreen .bar {
    background-image: linear-gradient(to right, #0b7e57, #0db67c);
}

.ui.menu .layout .item:before {
    background: none;
}

.ui.inverted.menu .layout .item:before {
    background: none !important;
}

.ui.card > .content.carousel {
    padding: 0px 0px;
}

.ui.card > .extra.content,
.ui.cards > .card > .extra.content {
    border-top: 0px !important;
}

.announcement a {
    /*color: black*/
}

.ui.cards > .card > .content.item-content {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0 !important;
    border: none !important;
}

.brightness {
    filter: grayscale(100%);
    filter: brightness(1);
    filter: contrast(0%);
    filter: invert(100%);
}

.ui.inverted.menu .active.item {
    border-bottom-width: 3px !important;
    border-bottom-style: solid;
    border-color: white !important;
    margin-bottom: 1px !important;
}

.ui.inverted.grey.menu .active.item,
.ui.menu .grey.active.item {
    border-color: white !important;
}

.ui.menu:not(.vertical) .item > .button.inverted:hover {
    background: rgba(255, 255, 255, 0.55);
    color: white;
}

.ui.dimmer > .content {
    top: 50px;
    position: absolute;
}

.align-center {
    align-items: center;
    justify-content: center;
}

.text-center {
    text-align: center;
}

@media (max-width: 1024px) {
    body:after {
        background-attachment: scroll !important;
    }

    * {
        -webkit-overflow-scrolling: touch;
    }

    .ui.container.fluid {
        margin: 0px !important;
    }

    .ui.menu {
        border: none !important;
        box-shadow: none !important;
    }

    .ui.menu .item:before {
        width: 0 !important;
    }

    .ui.menu .right.menu .item {
        padding: 15px 10px !important;
    }

    .ui.items > .item:first-child {
        margin-top: 0 !important;
    }

    .ui.header {
        font-size: 16px !important;
    }

    .pushable:not(body) {
        transform: none !important;
    }

    .pushable:not(body) > .ui.sidebar,
    .pushable:not(body) > .fixed,
    .pushable:not(body) > .pusher:after {
        margin-top: 51px !important;
        position: fixed !important;
    }

    .column.announcement img {
        width: 100%;
        height: auto;
    }

    .bg-text .ui.segment {
        padding: 14px;
    }

    .sidebar-menu-item {
        text-align: left !important;
    }

    .review-quantity {
        margin-top: 5px !important;
        min-height: 0 !important;
        min-width: 50px !important;
        padding: 5px !important;
    }

    .review-quantity > input,
    .review-quantity > i {
        padding: 5px !important;
    }

    .transparent {
        background: transparent !important;
    }

    .datepicker-modal {
        position: fixed !important;
    }

    .form-field-readonly {
        font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
        font-weight: 400;
        margin: 0;
        outline: 0;
        text-align: left;
        -webkit-appearance: none;
        line-height: 1.21428571em;
        padding: .67857143em 1em;
        font-size: 1em;
        background: #fff;
        border: 1px solid rgba(34, 36, 38, .15);
        color: rgba(0, 0, 0, .87);
        border-radius: .28571429rem;
        box-shadow: 0 0 0 0 transparent inset;
        transition: color .1s ease, border-color .1s ease;
    }
}

.social-links {
    padding: 25px;
    text-align: right
}

.social {
    display: inline-block;
    height: 39px;
    width: 40px;
    background-image: url('/social.png');
    margin: 3px;
    background-size: 150px 143px;
}

.social-instagram {
    background-position: 0px 0px
}

.social-facebook {
    background-position: 95px 0px
}

.social-twitter {
    background-position: 190px 0px
}

address {
    margin-bottom: 0px !important;
    text-align: left;
}

.footer.ui.menu .item {
    padding: 0px !important
}

@media only screen and (max-width: 767px) {
    .footer .ui.container {
        margin-left: 0px !important;
        margin-right: 0px !important;
        padding: 0px !important;
    }

    .ui.grid > .column:not(.row) {
        padding: 2px !important
    }

    .ui.grid > .column:not(.row) {
        padding: 10px !important
    }

    div address {
        text-align: center;
    }

    div.footer-contact-mobile {
        margin: auto;
        padding: 10px;
        display: block;
        text-align: center !important
    }

    div img.ui.small.image.footer-image-mobile {
        display: block;
        margin-left: auto;
        margin-right: auto;
        float: none;
        margin-bottom: 0px;
    }

    .social-links {
        text-align: center !important
    }
}

.carousel {
    background: transparent !important;
}

div.ready_to_pick_up div  img {
    width: 100% !important
}

.description img:not(.program):not(.footer-image-mobile) {
    width: 100% !important;
    height: auto;
}

#maindiv {
    margin-left: -5px !important;
}
#maindiv::-webkit-scrollbar{
    display: none;
}
#maindiv-mobile {
    margin-left: -5px !important;
}
#maindiv-mobile::-webkit-scrollbar{
    display: none;
}

.ui.dimmer > .content {
    top:50%;
    transform: translateY(-80%);
}
.ui.card .content.mobile-menu-item img , .ui.cards>.card .content .mobile-menu-item img {
    width: 100% !important;
    height: 100% !important;
    margin: auto;
}
.ui.fluid.card .content .description p {
    word-wrap: break-word;
}

div#root {
    overflow: hidden;
}

div.ui-banner.rewards {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
}
body {
    background: rgba(0, 0, 0, 0.95) !important;
}
@page { size: A4 landscape; }

